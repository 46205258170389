/** @jsx jsx */
import { jsx, Link as TLink, Flex } from "theme-ui"
import { Link } from "gatsby"

type NavigationProps = {
  nav: {
    title: string
    slug: string
  }[]
}

const Navigation = ({ nav }: NavigationProps) => (
  <Flex
    as="nav"
    sx={{
      flex: 1,
      justifyContent: `flex-start`,
      zIndex: `1`,
      order: [1, 2],
      "a:not(:last-of-type)": {
        mr: 4,
      },
      '@media screen and (max-width: 639px)': {
        display: `none`,
        // fontSize: 1,
        "a:not(:last-of-type)": {
          mr: 5,
        },
      },
    }}
    aria-label="Primary Navigation"
  >
    {nav.map((n) => (
      <TLink
        as={Link}
        sx={{ color: `text`, textTransform: `uppercase`, fontSize: `1rem`, ":hover": { color: `primary`, textDecoration: `none` } }}
        key={n.slug}
        to={n.slug}
      >
        {n.title}
      </TLink>
    ))}
  </Flex>
)

export default Navigation