/** @jsx jsx */
import { Flex, jsx, useColorMode, Link as TLink } from "theme-ui"
import { Link } from "gatsby"
import Navigation from "../../../../node_modules/@lekoarts/gatsby-theme-emma/src/components/navigation"
import SocialLinks from "./social-links"

type HeaderProps = {
  meta: {
    [key: string]: string
  }
  nav: {
    title: string
    slug: string
  }[]
}

const Header = ({ meta, nav }: HeaderProps) => {
  // const [colorMode, setColorMode] = useColorMode()
  // const isDark = colorMode === `dark`
  // const toggleColorMode = (e: any) => {
  //   setColorMode(isDark ? `light` : `dark`)
  // }

  const navEmpty = nav.length === 0

  return (
    <Flex sx={{
      position: `fixed`,
      top: `0`,
      width: `100%`,
      backgroundColor: `background`,
      zIndex: `98`,
      transition: `all 300ms ease-in-out`,
    }} as="header" variant="layout.header">
      {!navEmpty && <Navigation nav={nav} />}
      <Flex
        sx={{
          fontWeight: `regular`,
          textTransform: `uppercase`,
          letterSpacing: 6,
          fontSize: 2,
          flex: navEmpty ? 1 : [`1 0 100%`, 1],
          justifyContent: navEmpty ? `flex-start` : `center`,
          order: [1, 2],
          '@media screen and (max-width: 639px)': {
            marginBottom: `0.2rem`,
          },
        }}
      >
        <TLink
          aria-label={`${meta.siteTitle}, Back to homepage`}
          as={Link}
          sx={{ color: `text`, fill: `text`, ":hover": { color: `primary`, textDecoration: `none`, fill: `primary` } }}
          to="/"
        >
          <svg sx={{
            width: `50px`,
            display: `none`, // Logo disabled
            height: `33px`,
            marginRight: `1rem`,
            '@media screen and (max-width: 881px)': {
              marginRight: 0,
            },
          }} 
          viewBox="0 0 63 42">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.708 42L31.6615 19.7355L28.8706 23.3807L43.2399 42L28.2833 42L21.3765 33.1688L14.615 42L0 42L31.7307 0L63 42L48.708 42ZM21.3556 29.1206L29.4909 39.5224L38.1984 39.5224L25.7457 23.3867L31.6615 15.6601L49.9314 39.5225L58.0667 39.5224L31.7162 4.12923L4.97685 39.5224L13.3916 39.5224L21.3556 29.1206ZM12.8673 38.4607L21.3467 27.3857L30.0084 38.4606L36.0377 38.4606L24.4065 23.3892L31.6615 13.9134L50.4557 38.4607L55.9524 38.4607L31.71 5.89891L7.10978 38.4606L12.8673 38.4607Z"/>
            </svg>
          <span sx={{
            '@media screen and (max-width: 881px)': {
              display: `none`
            },
            }}>{meta.siteTitle}
          </span>
        </TLink>
      </Flex>
      <div
        sx={{
          a: {
            fontSize: 4,
            color: `text`,
            display: `flex`,
            alignItems: `center`,
            "&:hover": {
              color: `primary`,
            },
            "&:not(:first-of-type)": {
              ml: 2,
            },
            '@media screen and (max-width: 639px)': {
              width: 5,
              "&:not(:first-of-type)": {
                ml: 3,
              },
              "&:last-child": {
                width: `1.1rem`,
              },
            },
          },
          justifyContent: `flex-end`,
          flex: 1,
          display: `flex`,
          order: 3,
          '@media screen and (max-width: 639px)': {
            justifyContent: `center`,
          },
        }}
      >
        <SocialLinks />
        {/* <button
          sx={{ variant: `buttons.toggle`, fontWeight: `semibold` }}
          onClick={toggleColorMode}
          type="button"
          aria-label="Toggle dark mode"
        >
          {isDark ? `Light` : `Dark`}
        </button> */}
      </div>
    </Flex>
  )
}

export default Header