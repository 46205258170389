import React from "react"

const SocialLinks = () => (
  <>
    <a href="https://linkedin.com/in/adrian-füzi-bb7384190" target="_blank" rel="noopener noreferrer" aria-label="Linkedin">
      <svg
        fill="currentColor"
        viewBox="0 0 120 120"
        width="1em"
        height="1em"
      >
        <g id="linkedin" transform="translate(-4 -4)">
        <path id="Exclusion_1" data-name="Exclusion 1" d="M-417,587a59.626,59.626,0,0,1-23.355-4.715,59.8,59.8,0,0,1-19.072-12.858,59.8,59.8,0,0,1-12.859-19.072A59.623,59.623,0,0,1-477,527a59.624,59.624,0,0,1,4.715-23.355,59.8,59.8,0,0,1,12.859-19.072,59.8,59.8,0,0,1,19.072-12.859A59.624,59.624,0,0,1-417,467a59.623,59.623,0,0,1,23.355,4.715,59.8,59.8,0,0,1,19.071,12.859,59.8,59.8,0,0,1,12.858,19.072A59.626,59.626,0,0,1-357,527a59.624,59.624,0,0,1-4.715,23.355,59.8,59.8,0,0,1-12.858,19.072,59.8,59.8,0,0,1-19.071,12.858A59.625,59.625,0,0,1-417,587Zm0-112.811A52.872,52.872,0,0,0-469.812,527,52.872,52.872,0,0,0-417,579.813,52.872,52.872,0,0,0-364.188,527,52.872,52.872,0,0,0-417,474.188Z" transform="translate(481 -463)"/>
        <path id="iconfinder_LinkedIn_alt_367593" d="M46.718,72.891H58.86V109.43H46.718Zm6.152-17.613c-4.156,0-6.871,2.728-6.871,6.309,0,3.507,2.636,6.315,6.712,6.315h.077c4.235,0,6.872-2.808,6.872-6.315C59.582,58.006,57.024,55.278,52.871,55.278Zm35.8,16.755a12.054,12.054,0,0,0-10.943,6.031V72.891H65.583c.161,3.428,0,36.538,0,36.538H77.728V89.024a8.362,8.362,0,0,1,.4-2.965,6.65,6.65,0,0,1,6.23-4.441c4.4,0,6.154,3.352,6.154,8.262v19.549h12.144V88.478C102.655,77.255,96.665,72.033,88.672,72.033Z" transform="translate(-8.327 -21.354)"/>
        </g>
      </svg>
    </a>

    <a href="https://www.behance.net/adrianfzi" target="_blank" rel="noopener noreferrer" aria-label="Behance">
      <svg
        fill="currentColor"
        viewBox="0 0 120 120"
        width="1em"
        height="1em"
      >
        <g id="Frame_1" data-name="Frame 1" transform="translate(-4 -4)">
          <path id="Exclusion_1" data-name="Exclusion 1" d="M-417,587a59.626,59.626,0,0,1-23.355-4.715,59.8,59.8,0,0,1-19.072-12.858,59.8,59.8,0,0,1-12.859-19.072A59.623,59.623,0,0,1-477,527a59.624,59.624,0,0,1,4.715-23.355,59.8,59.8,0,0,1,12.859-19.072,59.8,59.8,0,0,1,19.072-12.859A59.624,59.624,0,0,1-417,467a59.623,59.623,0,0,1,23.355,4.715,59.8,59.8,0,0,1,19.071,12.859,59.8,59.8,0,0,1,12.858,19.072A59.626,59.626,0,0,1-357,527a59.624,59.624,0,0,1-4.715,23.355,59.8,59.8,0,0,1-12.858,19.072,59.8,59.8,0,0,1-19.071,12.858A59.625,59.625,0,0,1-417,587Zm0-112.811A52.872,52.872,0,0,0-469.812,527,52.872,52.872,0,0,0-417,579.813,52.872,52.872,0,0,0-364.188,527,52.872,52.872,0,0,0-417,474.188Z" transform="translate(481 -463)"/>
          <path id="Path_2" data-name="Path 2" d="M57,63s6-2.23,6-10S57.86,42,51,42H28V86H51s13,0,13-13C64,73,64,63,57,63ZM38,50H51s3,0,3,4c0,4.52-2,5-4,5H38ZM50,78H38V67l12.59-.07S55,67,55,73C55,78,51.81,77.95,50,78Z"/>
          <path id="Path_3" data-name="Path 3" d="M84.77,52.88c-16.69,0-16.68,16.68-16.68,16.68S67.17,86,85,86c0,0,15,1.39,15-11H92s.24,4.28-7,4.28c0,0-8,.79-8-7.28h23S102.34,52.88,84.77,52.88ZM92,66H77s1.24-6.76,7.94-6.76S92,66,92,66Z"/>
          <path id="Path_4" data-name="Path 4" d="M94,44H75v6H94Z"/>
        </g>
      </svg>
    </a>

    <a href="https://dribbble.com/Adrian-1111" target="_blank" rel="noopener noreferrer" aria-label="Dribbble">
      <svg
        stroke="currentColor"
        fill="currentColor"
        strokeWidth="0"
        viewBox="0 0 512 512"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M256 8C119.252 8 8 119.252 8 256s111.252 248 248 248 248-111.252 248-248S392.748 8 256 8zm163.97 114.366c29.503 36.046 47.369 81.957 47.835 131.955-6.984-1.477-77.018-15.682-147.502-6.818-5.752-14.041-11.181-26.393-18.617-41.614 78.321-31.977 113.818-77.482 118.284-83.523zM396.421 97.87c-3.81 5.427-35.697 48.286-111.021 76.519-34.712-63.776-73.185-116.168-79.04-124.008 67.176-16.193 137.966 1.27 190.061 47.489zm-230.48-33.25c5.585 7.659 43.438 60.116 78.537 122.509-99.087 26.313-186.36 25.934-195.834 25.809C62.38 147.205 106.678 92.573 165.941 64.62zM44.17 256.323c0-2.166.043-4.322.108-6.473 9.268.19 111.92 1.513 217.706-30.146 6.064 11.868 11.857 23.915 17.174 35.949-76.599 21.575-146.194 83.527-180.531 142.306C64.794 360.405 44.17 310.73 44.17 256.323zm81.807 167.113c22.127-45.233 82.178-103.622 167.579-132.756 29.74 77.283 42.039 142.053 45.189 160.638-68.112 29.013-150.015 21.053-212.768-27.882zm248.38 8.489c-2.171-12.886-13.446-74.897-41.152-151.033 66.38-10.626 124.7 6.768 131.947 9.055-9.442 58.941-43.273 109.844-90.795 141.978z" />
      </svg>
    </a>

    <a href="https://github.com/adrianfuzi" target="_blank" rel="noopener noreferrer" aria-label="Github">
      <svg
        fill="currentColor"
        height="1em"
        id="Layer_12"
        viewBox="0 0 512 512"
      >
        <path clip-rule="evenodd" d="M192,368.004c0-8.844,7.156-16,16-16s16,7.156,16,16  s-7.156,16-16,16S192,376.848,192,368.004L192,368.004z M349.328,494.16c-4.266,1.219-8.672,2.094-13.328,2.094  c-26.516,0-48-21.484-48-48v-58.188c0-20.094,12.898-37.156,30.797-43.438C353.164,335.082,384,306.082,384,272.004V240  c0-15.164-6.188-29.285-16-41.367V162.5c0-17.668-14.328-23.719-32-13.496l-24.516,14.176C303.633,161.145,295.703,160,288,160h-64  c-7.699,0-15.633,1.145-23.484,3.18L176,149.004c-17.668-10.223-32-4.172-32,13.496v36.133c-9.812,12.082-16,26.203-16,41.367  v32.004c0,23.281,14.488,44.188,34.578,58.812l-0.02,0.031c4.172,2.859,6.945,7.688,6.945,13.156c0,8.828-7.176,16-16,16  c-4.52,0-8.574-1.891-11.48-4.906C115.004,334.629,96,305.035,96,272.004V240c0-18.523,6.012-35.977,16-51.375v-47.633  c0-35.336,28.645-47.438,64-26.996l27.461,15.887C210.309,128.719,217.172,128,224,128h64c6.828,0,13.688,0.719,20.539,1.883  L336,113.996c35.359-20.441,64-8.34,64,26.996v47.633c9.984,15.398,16,32.852,16,51.375v32.004  c0,47.609-39.25,88.141-85.531,104.359c-0.055,0.047-0.109,0.172-0.188,0.188c-6.016,2.312-10.281,8.125-10.281,14.953v56.75  c0,8.844,7.156,16,16,16c1.336,0,2.562-0.375,3.797-0.688C421.969,430.41,480,350.066,480,256c0-123.715-100.281-224-224-224  C132.285,32,32,132.285,32,256c0,97.41,62.254,180.066,149.121,210.895c0.445,0.047,0.852,0.234,1.316,0.234  c5.277,0,9.562-4.297,9.562-9.562c0-5.281-4.285-9.562-9.562-9.562c-0.113,0-0.113-0.094-0.191-0.141  c-53.16-1.422-53.219-63.859-70.246-63.859c-8.844,0-16-7.156-16-16s7.156-16,16-16c1.688,0,4.207,0,7.988,0  c32.02,0,27.445,64,72.012,64c17.668,0,32,14.328,32,32v28c0,15.453-12.527,28-28.004,28c-1.688,0-3.277-0.344-4.887-0.656  C81.203,474.613,0,374.926,0,256C0,114.617,114.617,0,256,0s256,114.617,256,256C512,364.41,444.508,456.848,349.328,494.16  L349.328,494.16z"/>
      </svg>
    </a>
  </>
)

export default SocialLinks