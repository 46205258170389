import React, { useState } from "react"
import { Link } from "gatsby"
import { Global } from "@emotion/core"
import { Box } from "theme-ui"
import useSiteMetadata from "../../../../node_modules/@lekoarts/gatsby-theme-emma/src/hooks/use-site-metadata"
import useNavigation from "../../../../node_modules/@lekoarts/gatsby-theme-emma/src/hooks/use-navigation"
import Footer from "./footer"
import Header from "./header"
import SEO from "../../../../node_modules/@lekoarts/gatsby-theme-emma/src/components/seo"


type LayoutProps = { children: React.ReactNode; className?: string }

const Layout = ({ children, className = `` }: LayoutProps) => {
  const meta = useSiteMetadata()
  const nav = useNavigation()

    // Burger behavior
    const [isOpen, setIsOpen] = useState(false)

    // Nav scroll visibility
    if (typeof window !== `undefined`) {
      const body = document.body;
      let lastScroll = 0;
    
      window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0) {
          body.classList.remove("scroll-up");
          return;
        }
      
        if (currentScroll > lastScroll && !body.classList.contains("scroll-down")) {
          body.classList.remove("scroll-up");
          body.classList.add("scroll-down");
        } else if (
          currentScroll < lastScroll &&
          body.classList.contains("scroll-down")
        ) {
          body.classList.remove("scroll-down");
          body.classList.add("scroll-up");
        }
        lastScroll = currentScroll;
      });
    }

  return (
    <React.Fragment>
      <Global
        styles={(theme) => ({
          "*": {
            boxSizing: `inherit`,
          },
          html: {
            WebkitTextSizeAdjust: `100%`,
          },
          img: {
            borderStyle: `none`,
          },
          pre: {
            fontFamily: `monospace`,
            fontSize: `1em`,
          },
          "[hidden]": {
            display: `none`,
          },
          "::selection": {
            backgroundColor: theme.colors.text,
            color: theme.colors.background,
          },
          a: {
            transition: `all 0.3s ease-in-out`,
          }
        })}
      />
      <SEO />
      <Header meta={meta} nav={nav} />
      <Box as="main" variant="layout.main" className={className}>
        {children}
      </Box>
        <div
          className={`burger ${isOpen ? "burger__change" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={() => setIsOpen(!isOpen)}
          role="button">
          <div className="burger__bar-1" />
          <div className="burger__bar-2" />
          <div className="burger__bar-3" />
        </div>
        <div className={`burger-menu ${isOpen ? "burger-menu-visible" : ""}`}>
          <ul className="burger-menu__nav">
            <li className="burger-menu__nav-link">
              <Link to="/">Home</Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link to="/about">About</Link>
            </li>
            <li className="burger-menu__nav-link">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      <Footer />
    </React.Fragment>
  )
}

export default Layout